import { objectToTranslatableChoices } from '@shared/choice';
export var UserTitle;
(function (UserTitle) {
    UserTitle["Mister"] = "mister";
    UserTitle["Miss"] = "miss";
})(UserTitle || (UserTitle = {}));
export var ClaimLanguage;
(function (ClaimLanguage) {
    ClaimLanguage["French"] = "fr";
    ClaimLanguage["English"] = "en";
    ClaimLanguage["Italiano"] = "it";
})(ClaimLanguage || (ClaimLanguage = {}));
export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "ROLE_ADMIN";
    UserRole["VALUATION_OPTIONS"] = "VALUATION_OPTIONS";
    UserRole["MANAGER"] = "ROLE_MANAGER";
    UserRole["MANAGER_READONLY"] = "ROLE_MANAGER_READONLY";
    UserRole["BACKOFFICE_TOOL_PRODUCTS"] = "ROLE_BACKOFFICE_TOOL_PRODUCTS";
    UserRole["BACKOFFICE_DAMAGES_VALUATION"] = "ROLE_BACKOFFICE_DAMAGES_VALUATION";
    UserRole["BACKOFFICE_HOTLINE"] = "ROLE_BACKOFFICE_HOTLINE";
    UserRole["BACKOFFICE"] = "ROLE_BACKOFFICE";
    UserRole["USER"] = "ROLE_USER";
})(UserRole || (UserRole = {}));
export const USER_TITLE_TRAD_KEYS = {
    [UserTitle.Mister]: 'user.title.mister',
    [UserTitle.Miss]: 'user.title.miss',
};
export const USER_ROLE_TRAD_KEYS = Object.values(UserRole).reduce((tradKeys, role) => {
    const key = role.replace(/^role_/i, '').toLowerCase();
    tradKeys[role] = `user.role.${key}`;
    return tradKeys;
}, {});
export const USER_ROLE_CHOICES = objectToTranslatableChoices(USER_ROLE_TRAD_KEYS);
